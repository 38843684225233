// FIXME enable eslint and fix the above error

import moment from 'moment';
import { useCallback, useEffect, useReducer } from 'react';
import {
  cacheBusterStringSelector,
  defaultMaintenanceState,
  isInMaintenanceSelector,
  maintenanceReducer,
  POLLING_INTERVAL,
} from '../../state/maintenanceState';
import { MaintenanceContext } from '../../utils/useMaintenance';
import MaintenancePage from './MaintenancePage';
import Loading from './components/Loading';

const getMaintenanceLocation = (): string => {
  return `${process.env.VITE_MYROTRA_CDN}maintenance.json`;
};

type PropType = {
  children: React.ReactNode;
};

function MaintenanceGuard({ children }: PropType) {
  const [state, dispatch] = useReducer(
    maintenanceReducer,
    defaultMaintenanceState
  );
  const isInMaintenance = isInMaintenanceSelector(state);
  const cacheBusterString = cacheBusterStringSelector(state);

  const fetchMaintenance = useCallback(() => {
    fetch(getMaintenanceLocation() + `?cacheBuster=${cacheBusterString}`, {
      cache: 'no-cache',
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: 'updateMaintenance',
          payload: response,
        });
      })
      .catch(() => {
        // we don't care what error occurred, we are going to assume we are NOT in maintenance mode
        dispatch({
          type: 'updateMaintenance',
          payload: {
            inMaintenance: false,
            notificationMessage: '',
            maintenanceMessage: '',
          },
        });
      });
  }, [cacheBusterString]);

  // call to maintenance file everytime the fetchMaintenance callback is changed,
  // which is changed everytime that the cachebusterString is changed
  useEffect(() => {
    fetchMaintenance();
  }, [fetchMaintenance]);

  // scheduled update of the cachebuster time
  useEffect(() => {
    const interval = setInterval(
      () =>
        dispatch({
          type: 'updateCacheBusterTime',
          payload: moment.utc(),
        }),
      POLLING_INTERVAL
    );
    return () => clearInterval(interval);
  }, []);

  // time ticker to update current time in state
  useEffect(() => {
    const interval = setInterval(
      () =>
        dispatch({
          type: 'updateTime',
          payload: moment.utc(),
        }),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  if (state.pending) return <Loading />;
  return (
    <MaintenanceContext.Provider value={{ state, dispatch }}>
      {isInMaintenance ? <MaintenancePage /> : children}
    </MaintenanceContext.Provider>
  );
}

export default MaintenanceGuard;
