import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { ChevronRight, MailOutline, Phone } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import { useMemo } from 'react';

interface BlockedUserWrapperProps {
  children: JSX.Element;
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  fontSize: 'inherit',
  '& > svg': {
    marginRight: theme.spacing(0.5),
    fontSize: '16px',
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledSalesSpan = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > span': {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: '1rem',
      marginRight: theme.spacing(0.5),
    },
  },
}));

function BlockedUserWrapper({ children }: BlockedUserWrapperProps) {
  const auth0 = useAuth0();

  const isBlocked = useMemo(() => {
    return (
      auth0.error &&
      auth0.error instanceof OAuthError &&
      auth0.error.error === 'unauthorized'
    );
  }, [auth0.error]);

  if (isBlocked) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `url(./static/login-page/rotraNext-background.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Paper
          sx={{
            p: 4,
            m: 4,
            maxWidth: '600px',
          }}
          square
        >
          <Grid container>
            <Grid item xs={12} sm={2}>
              <Box
                sx={{
                  textAlign: {
                    xs: 'center',
                    sm: 'left',
                  },
                }}
              >
                <img src="/static/logo.png" alt="Rotra Logo" width={80} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} pt={3} pb={{ sm: 3 }}>
              <Typography align="center" variant="h5" gutterBottom>
                Your account is blocked
              </Typography>
              <Typography align="center" variant="body2">
                Please contact your account manager or our Customer Success team
                using the provided details below to resolve your issue. They
                will be more than happy to assist you.
              </Typography>
              <Typography fontSize="0.8rem" pt={2}>
                <StyledSalesSpan>
                  <span>
                    <StyledLink href="mailto:cs@rotra.nl">
                      <MailOutline /> cs@rotra.nl
                    </StyledLink>
                  </span>
                  <span>
                    <StyledLink href="tel:31102961988">
                      <Phone /> +31 10 296 1988
                    </StyledLink>
                  </span>
                </StyledSalesSpan>
              </Typography>
              <Typography align="center" pt={2}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ textTransform: 'unset' }}
                  color="secondary"
                  onClick={() => {
                    void auth0.logout({
                      logoutParams: {
                        returnTo: window.location.origin,
                      },
                    });
                  }}
                >
                  Sign in with a different account <ChevronRight />
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Paper>
      </div>
    );
  }

  return children;
}

export default BlockedUserWrapper;
